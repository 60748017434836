@import url("https://fonts.googleapis.com/css?family=Shadows+Into+Light|Quicksand|Playfair+Display:700i,900");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Roboto&display=swap');

article {
  margin: 2rem auto 0;
  width: 90%;
  max-width: 800px;
  padding: 20px 10px;
}

h1 {
  font-family: "roboto", serif; 
  font-size: 48px;
  text-align: left;
  margin-bottom: 8px;
}

h2 {
  /* font-family: "Shadows Into Light", sans-serif; */
  font-size: 40px;
  font-weight: 700;
  padding: 0;
  margin: 40px 0 40px 0px;
  text-align: left;
  line-height: 34.5px;
  letter-spacing: -0.45px;
}

p,
i,
li {
  margin-top: 21px;
}

p,
i,
li {
  font-family: "Quicksand";
  font-size: 21px;
  letter-spacing: -0.03px;
  line-height: 1.58;
}

li {
  list-style-type: square;
  margin-left: 40px;
}

blockquote {
  font-family: "Playfair Display", serif;
  font-size: 25px;
  font-style: italic;
  letter-spacing: -0.36px;
  line-height: 44.4px;
  overflow-wrap: break-word;
  margin: 30px 0 33px 0;
  padding: 0 0 0 1em;
  border-left: .25em solid #dfe2e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 3px 5px;
}

pre > code {
  background: transparent !important;
  font-family: "Quicksand";
  font-size: 18px;
  padding: 0px;
}

pre {
  margin-top: 30px;
  padding: 20px !important;
  background-color: white;
  color: black;
  overflow-y: scroll;
  height: 500px;
}

mark,
.highlighted {
  background: #7dffb3;
}

.first-letter {
  overflow-wrap: break-word;
  font-family: "Playfair Display", serif;
  font-size: 60px;
  line-height: 60px;
  display: block;
  position: relative;
  float: left;
  margin: 0px 7px 0 -5px;
}

.subtitle {
  font-family: "Shadows Into Light", sans-serif;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 24px 0;
}

/* Slide up */

.blog-post-anchor:hover {
  box-shadow: inset 0 -1.125em 0 #434C5E;
  color: white;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}




