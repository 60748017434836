/*Tailwind base*/

@tailwind base;
@tailwind components;
@tailwind utilities;

.App-header {
    background-color: #0a192f;
    min-height: 100vh;
    color: white;
}

