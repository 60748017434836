.card:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
}

.card-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card-description img{
    display: none;
}

.card-description hr{
    display: none;
}

.card-description p{
    
}
